
// exports.BASE_URL = 'https://gadienterprises-gamc-qa.chetu.com/api/';
// exports.ImageUrl = 'https://gadienterprises-gamc-qa.chetu.com/images/';
exports.BASE_URL = `${window.location.origin}/backend/api/`;
exports.ImageUrl = `${window.location.origin}/backend/images/`;
exports.LOGIN_API = 'Account/Login';
exports.FORGET_API = 'Account/ResetPassword';
exports.REFRESH_TOKEN_API = 'Account/RefreshToken';
exports.AUCTION_AUCTIONDATABYFILTER = "Auction/AuctionDataByFilter";
exports.LINK_VERIFY_API = 'Account/EmailLinkConfirmation';
exports.UPDATE_PASSWORD_API = 'Account/ResetPasswordConfirmation';
exports.CHANGE_PASSWORD_API = 'Account/ChangePassword';
exports.EMAIL_EXISTS = 'Account/CheckEmailExists';
exports.ORGANIZATION_REGISTRATION_API = 'Manager/Add';
exports.ORGANIZATION_GETALL_API = 'Manager/GetAll';
exports.ORGANIZATION_Delete_API = 'Manager/DeleteBy/';
exports.ORGANIZATION_ViewID_API = 'Manager/GetBy/';
exports.ORGANIZATION_Update_API = 'Manager/update';
exports.ORGANIZATION_Country_API = 'Common/countries';
exports.ORGANIZATION_State_API = 'Common/states/';
exports.ORGANIZATION_City_API = 'Common/cities/';
exports.ORGANIZATION_GetRecent = 'Manager/GetRecent';
exports.ORGANIZATION_TotalCount = 'Manager/GetCount';
exports.USER_REGISTRATION_API = 'Player/Add';
exports.USER_TotalCount = 'Player/GetCount';
exports.USER_GetAll = 'Player/GetAll';
exports.USER_GETID = 'Player/GetBy/';
exports.SPORTS_REGISTRATION_API = 'Sports/Add';
exports.UPLOAD_IMAGE = 'Common/UploadImage';
exports.NOTIFICATION_MESSAGE_REG = 'Announcement/Notification';
exports.SPORTS_UPDATE = 'Sports/Update';
exports.SPORTS_GETALL = 'Sports/GetAll';
exports.SPORTS_DELETE = 'Sports/Delete/';
exports.SPORTS_Total = 'Sports/GetCount';
exports.WEB_ADMIN_PROFILE = 'Common/UpdateProfile';
exports.TEAM_GETALL_API = 'Team/GetAll';
exports.TEAM_REG_API = 'Team/Add';
exports.TEAM_DELETE_API = 'Team/Delete/';
exports.TEAM_TOTAL_API = 'Team/GetCount';
exports.TEAM_UPDATE_API = 'Team/Update';
exports.TOURNAMENT_REG = 'Tournament/Add';
exports.TOURNAMENT_GET = 'Tournament/GetAll';
exports.TOURNAMENT_GET_RECENT = 'Tournament/GetRecent';
exports.TOURNAMENT_DELETE = 'Tournament/Delete/';
exports.TOURNAMENT_GET_COUNT = 'Tournament/GetCount';
exports.TOURNAMENT_GET_ID = 'Tournament/GetBy/';
exports.TOURNAMENT_GET_ID_BRACKETS = "Tournament/Bracket/";
exports.TOURNAMENT_BRACKETS_ID = "Tournament/BracketById/";
exports.TOURNAMENT_UPDATE = 'Tournament/Update';
exports.AUCTION_REG = 'Auction/Add';
exports.AUCTION_GET_ALL = 'Auction/GetAll';
exports.AUCTION_GET_COUNT = 'Auction/GetCount';
exports.AUCTION_DELETE_API = 'Auction/Delete/';
exports.AUCTION_UPDATE = 'Auction/Update';
exports.AUCTION_GETBYID = 'Auction/GetBy';
exports.AUCTION_RECENT = 'Auction/GetRecent';
exports.REACT_APP_API_SECRET = "XkhZG4fW2t2W";
exports.BID_GET_COUNT = "Bid/GetCount";
exports.AUCTION_UPDATE = 'Auction/Update';
exports.NOTIFICATION_MESSAGE = 'Announcement/GetNotificationData';
exports.REG_BID = "Bid/PlaceBid";
exports.SMREG_BID = "SecondaryMarket/SMPlaceBid";
exports.CUSTOMER_ADD = "Customer/Add";
exports.PAYMENT_ADD = "Payment/Add";
exports.AUCTIONGETTEAM = "Auction/GetAuctionTeams";
exports.AUCTIONWININGCOUNT = "Auction/WinnerCount";
exports.TEAMGETBYSPORT = "Team/GetTeamsBySportsId/";
exports.GETBIDBYMANAGER = "Bid/GetBidsbyManagerId";
exports.GETALL_BID = "Bid/GetAll";
exports.RECENT_BID = "Bid/GetRecent";
exports.CUSTOMER_PAYMENT = "Stripe/CreateCustomer";
exports.CREATEPAYMENTURL = "Stripe/CreateCustomerAndPaymentSession?subscriptionId";
exports.CHECKOUTPAYMENT = "Stripe/Checkout-Session";
exports.CHECKOUTPAYMENTSTATUS = "Stripe/GetPaymentStatus";

exports.PAYMENTHISTORY = 'Stripe/GetAllPaymentHistory';
exports.ADMIN_GET = "Manager/GetAdmin";
exports.BID_STATUS = "Bid/GetBidWithStatus";
exports.BRACKETSUFFLE = "Tournament/SuffleTeams";
exports.ADMIN_REGISTER = "Manager/RegisterAdmin";
exports.ANNOUNCEMENT_GETALLAUCTION = "Announcement/GetAllAuction";
exports.ANNOUNCEMENT_GETANNOUNCEMENTDATA = "Announcement/GetAnnouncementData";
exports.MANAGER_GETBYID = "Manager/GetById";
exports.GETBYIDPAYMENT = "Stripe/GetUserPaymentHistory";
exports.BID_GETBIDWITHSTATUS = "Bid/GetBidWithStatus";
exports.BID_GETBIDWITHSTATUS = "Bid/GetBidWithStatus";
exports.SECODARY_MARKETAUCTION ="SecondaryMarket/SMGetAllWinAuction";
exports.SECODARY_MARKETAUCTION_WINNER ="SecondaryMarket/SMGetAllAuctionStatus";
exports.TOURNAMENT_BRACKETS_UPDATE ="Tournament/UpdateMatchScore";
exports.TOURNAMENT_BRACKETS_DATE ="Tournament/UpdateMatchDate";
exports.AUCTION_AUCTIONPLAYPAUSESTATUS="Auction/AuctionPlayPauseStatus";
exports.CONTACTUS="Common/ContactUs";
exports.CONTACTUSDETAILS="Common/GetContactUsDetails";
exports.INVOICEDETAILS="Common/GetInvoice"
exports.MANAGER_GETALL = "Manager/GetAll";
exports.PLAYER_SUBSCRIBEMANAGER = "Player/SubscribeManager";
exports.SECODARY_MARKETAUCTION_SMBIDSELL ="SecondaryMarket/SMBidSell";
exports.MANAGER_GETPLAYERREQUEST = "Manager/GetPlayerRequest";
exports.MANAGER_PLAYERAPPROVAL = "Manager/PlayerApproval";
exports.PLAYER_GETSUBSCRIBE_MANAGER = "Player/GetSubscribedManager";
exports.GETBIDWINNER = "Bid/GetMyBidWinings";
exports.GETBIDCOUNTDATA = "Bid/GetMyBidCount";
exports.SECONDARYBIDH = "SecondaryMarket/SMBidHistory";
exports.BID_GETAUCTION_TEAMWINNER = "Bid/GetAuctionTeamWinner";
exports.SUBCRIPTIONREVENUE = "Common/GetSubscriptionRevenue"
exports.SECODARY_MARKETAUCTION_ANNOUNCEMENT = "SecondaryMarket/SMAnnouncement"
exports.ANNOUNCEMENT_GETWINNERDEATILS = "Announcement/GetWinnerDetails"
exports.REPORTDATAAPI = "Common/GetSubscriptionCount"
exports.SUBSCRIPTION_CONT = "Common/GetSubscriptionCount"
exports.SM_MY_BID_HISTORY = "SecondaryMarket/SMMyBidHistory"
