
import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import '../../assets/css/scss/main.css';
import * as icons from 'react-bootstrap-icons';
import RightSideBanner from './RightSideBanner';
import { useNavigate } from 'react-router-dom';
import { EmailCheck, UserReg } from '../../redux/auth-reducer/auth-service';
import { toast } from 'react-toastify';
import validator from "validator";



function UserRegistration() {
  const [validated, setValidated] = useState(false);
  const navigate = useNavigate();
  let [visible, setVisible] = useState(false);
  let [visiblePass, setVisiblePass] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [firstNameError, setFirstNameError] = useState(true);
  const [lastNameError, setLastNameError] = useState(true);
  const [message, setMessage] = useState("");
  const [err, setErr] = useState("");
  const [errMessage, setErrMessage] = useState(false);
  const [val, setVal] = useState();

  const [regForm, setRegForm] = useState({
    firstName: '',
    lastName: '',
    emailId: '',
    password: '',
    confirmPassword: '',
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    setValidated(true);

    const data = [{
      "crudOperationId": true,
      "id": 0,
      "firstName": regForm?.firstName,
      "lastName": regForm?.lastName,
      "emailId": regForm?.emailId,
      "RoleId": 4,
      "password": regForm?.password,
      "confirmPassword": regForm?.confirmPassword,
    }]
    var mailformat = /^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}$/;
    if (regForm?.emailId?.match(mailformat) && regForm?.firstName && regForm?.lastName) {
      setErrMessage(false);
      try {
        await UserReg(data).then((response) => {
          if (response?.status == 200) {

            toast.success(response?.data?.message, {
              toastId: 'success1',
            })
            setTimeout(() => {
              navigate('/Login');
            }, 2000)
          } else {

          }

        })
      }
      catch (error) {
        toast.error(error?.response?.data?.title, {
          toastId: 'error1',
        })
      }
    }
    else {
      setErrMessage(true);

    }




  };


  const show = (event) => {
    setVisible(!visible)
  }
  const showConfirm = () => {
    setVisiblePass(!visiblePass)
  }

  const handelChange = (e) => {
    setRegForm({
      ...regForm,
      [e.target.name]: e.target.value,
    },)
  }

  const handleChange = (e) => {
    const inputValue = e.target.value.replace(/[^a-zA-Z\s]/g, '');
    setRegForm({
      ...regForm,
      [e.target.name]: inputValue,
    },)
  }



  const validateEmail = (e) => {
    const email = e.target.value;

    if (validator.isEmail(email)) {
      setMessage('')
    } else {

    }

    setRegForm({
      ...regForm,
      emailId: e.target.value,

    },)
    const value = e.target.value;


    const EmailExist = value.match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )

    if (EmailExist) {
      selectEmail(value)
    }
  }


  async function selectEmail(email) {
    const data = {
      "email": email
    }
    await EmailCheck(data).then((res) => {
      if (res?.data?.status == true) {
        toast.error(res?.data?.message);
      }
      else {
        
      }

    })
  }
  const handleLastNameError = (e) => {
    setLastNameError(isNameValid(e.target.value));
  }
  const handleFirstNameError = (e) => {
    setFirstNameError(isNameValid(e.target.value));
  }
  const handelformPass = (e) => {
    setRegForm({
      ...regForm,
      [e.target.name]: e.target.value.trim(),

    },)

    setIsValid(isPasswordValid(e.target.value));


  }
  const handelformPassConfirm = (e) => {
    setRegForm({
      ...regForm,
      [e.target.name]: e.target.value.trim(),

    },)
  }

  useEffect(() => {
    if (regForm?.password !== regForm?.confirmPassword && regForm?.password?.length > 0 && regForm?.confirmPassword?.length > 0) {
      setErr("Password and confirmPassword does not match")
    } else {
      setErr("")
    }
  }, [regForm?.password, regForm?.confirmPassword])

  const isPasswordValid = (password) => {
    const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W).{6,}$/;
    return passwordRegex.test(password);
  };
  const isNameValid = (name) => {
    const nameRegex = /^[A-Za-z]+[A-Za-z ]*$/;
    return nameRegex.test(name);
  };


  const handleKeyDown = e => {
    if (e.key === " ") {
      e.preventDefault();
    }
  };
  const backBtn = () => {
    navigate(-1)
  }
  return (
    <>



      <div className='grop-section'>
        <div className='icon-back'>
          <icons.ArrowLeft onClick={backBtn} />
        </div>
        <div className='group-reg'>
          <div className='item'>
            <div className='inner-item'>
              <div className='form-contnet'>
                <h1 className='heading'>Get Started Now</h1>

                <p>Create a new account </p>
              </div>
              <Form noValidate validated={validated} className='registration' onSubmit={handleSubmit}>
                <Row className="mb-3">

                  <Form.Group as={Col} md="6" controlId="validationCustomFristname">
                    <Form.Label>First name</Form.Label>
                    <InputGroup hasValidation>
                      <Form.Control
                        type="text"
                        name="firstName"
                        onChange={handleChange}
                        onKeyUp={handleFirstNameError}
                        value={regForm?.firstName}
                        placeholder="Enter your first name"
                        aria-describedby="inputGroupPrepend"
                        required
                        isInvalid={!firstNameError}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter a valid first name.
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>

                  <Form.Group as={Col} md="6" className="text-left">
                    <Form.Label>Last name</Form.Label>
                    <InputGroup hasValidation>
                      <Form.Control
                        type="text"
                        name="lastName"
                        onChange={handleChange}
                        onKeyUp={handleLastNameError}
                        value={regForm?.lastName}
                        placeholder="Enter your last name"
                        aria-describedby="inputGroupPrepend"
                        required
                        isInvalid={!lastNameError}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter a valid last name.
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>






                  <Form.Group as={Col} md="12" className="text-left" >
                    <Form.Label>Email</Form.Label>
                    <InputGroup hasValidation>
                      <Form.Control
                        type="email"
                        maxlength="50"
                        onChange={validateEmail}
                        name="emailId"
                        value={regForm?.emailId}
                        onKeyDown={handleKeyDown}
                        placeholder="Enter your email"
                        autocomplete="off"
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter a Email.
                      </Form.Control.Feedback>
                      <p
                        style={{
                          color: "#dc3545",
                          width: "100%",
                          marginTop: "5px"
                        }}
                      >
                        {message}
                      </p>


                    </InputGroup>
                  </Form.Group>


                  <Form.Group as={Col} md="12" >
                    <Form.Label>Password</Form.Label>
                    <InputGroup hasValidation>


                      <Form.Control
                        type={visible ? "text" : "password"}
                        onChange={handelformPass}
                        value={regForm?.password}
                        name="password"
                        onPaste={(e) => {
                          setVal(e.target.value.trim());
                        }}
                        onCopy={(e) => {
                          setVal(e.target.value.trim());
                        }}
                        maxLength={20}
                        placeholder="Enter password"
                        aria-describedby="inputGroupPrepend"
                        isInvalid={!isValid}
                        autocomplete="off"
                        required
                      />
                      <div className='eye-icon' onClick={show}>
                        {
                          visible ? <icons.Eye /> : <icons.EyeSlash />
                        }

                      </div>

                      <Form.Control.Feedback type="invalid">
                        Password must be at least 6 characters long and contain at least one uppercase letter, one lowercase letter, one number, and one special character.
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>


                  <Form.Group as={Col} md="12" className="text-left" >
                    <Form.Label>Confirm Password</Form.Label>
                    <InputGroup hasValidation>




                      <Form.Control
                        type={visiblePass ? "text" : "password"}
                        name='confirmPassword'
                        autocomplete="on"
                        maxLength={20}
                        onChange={handelformPassConfirm}
                        value={regForm?.confirmPassword}

                        placeholder="Enter confirm password"
                        required
                      />
                      <div className='eye-icon' onClick={showConfirm}>
                        {
                          visiblePass ? <icons.Eye /> : <icons.EyeSlash />
                        }

                      </div>
                      {
                        err ? <p className='err-message'>Password and confirm Password does not match</p> : <Form.Control.Feedback type="invalid">
                          Please Enter a Confirm Password.
                        </Form.Control.Feedback>
                      }

                    </InputGroup>
                  </Form.Group>
                  <Col md="12">
                    <Button type="submit" className='submit'>Submit</Button>
                  </Col>
                </Row>
              </Form>
              <p className='account-para'>Already have an account? <a href="/Login">Login here</a></p>
            </div>
          </div>
          <RightSideBanner />
        </div>
      </div>

    </>
  )
}
export default UserRegistration;