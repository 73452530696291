import React, { useEffect, useState } from 'react'
import Sidebar from "../../CommonComponent/sidebar/Sidebar";
import TopNavbar from "../../CommonComponent/topNavbar/TopNavbar";
import { Footer } from "../../Footer";
import DataTable from 'react-data-table-component';
import { GetContactUsDetails } from '../../redux/auth-reducer/auth-service';

export const Query = () => {

  const [data, setData] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [dataListItem, setDataListItem] = useState([]);
  function sidebarfun() {
    setData(!data)
  }

  useEffect(() => {
    const GetDataQuries = async () => {
      await GetContactUsDetails().then((res) => {
        setDataListItem(res?.data?.data?.dataList)
      })
    }
    GetDataQuries();
  }, [])

  const columns = [
    {
      name: 'ID',
      selector: (row, index) => index + 1,

    },

    {
      name: 'First Name',
      selector: (row) => row?.firstName,
      sortable: true,
    },
    {
      name: 'Last Name',
      selector: (row) => row?.lastName,
      sortable: true,
    },
    {
      name: 'Email',
      selector: (row) => row?.emailId,
      sortable: true,
    },
    {
      name: 'Message',
      selector: (row) => row?.message || '',
      sortable: true,
      cell: (row) => (
        <p className='message-query'>
          {row?.message}
        </p>
      ),
    },

  ];


 
  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };
  const filteredData = searchText ? dataListItem?.filter((item) => {
    return item?.firstName?.toLowerCase().includes(searchText?.toLowerCase()) || item?.lastName?.toLowerCase().includes(searchText?.toLowerCase()) || item?.emailId?.toLowerCase().includes(searchText?.toLowerCase())

  })

    : dataListItem;
  return (
    <div className="dasboard-group" >
      <TopNavbar sidebarfun={sidebarfun} />
      <Sidebar data={data} />
      <div className={data ? "dashboard-content full-contnet" : "dashboard-content"}>
        <h1 className="heading-dash">Queries List</h1>
        <div className='group_data_table'>


          <div className="table-transaction">
            <h1 className="subscription_heading ms-4 quries">Queries List</h1>
            <div className='search-input filter d-flex align-items-center me-4'>


              <label className='me-3'>Search</label>
              <input type="text" className='form-control mb-3 mt-3' value={searchText} onChange={handleSearch} />
            </div>
            <DataTable

              columns={columns}
              data={filteredData}
              pagination
              paginationPerPage={5}
              paginationRowsPerPageOptions={[5, 10, 15, 20]}
              paginationTotalRows={filteredData?.length}
            />
          </div>
        </div>
      </div>
      <div className={data ? "footer" : "footer-content"}>
        <Footer />
      </div>
    </div>
  )
}
