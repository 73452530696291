import axios from "axios";
import { BASE_URL, REFRESH_TOKEN_API } from "./config";
import { GetRefreshToken, GetToken, SetRefreshToken, SetToken } from "../helper/helper";
import { useNavigate } from "react-router-dom";

axios.interceptors.request.use(
    config => {
        const accessToken = GetToken()
        if (accessToken) {
            config.headers['Authorization'] = `Bearer ${accessToken}`;
        }
        return config;
    },
    error => {
        Promise.reject(error);
    }
);



axios.interceptors.response.use(function (response) {

    return response;
}, async error => {
    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        try {
            const payload = {
                status: true,
                token: GetToken(),
                refreshToken: GetRefreshToken(),
                message: "string"
            }
            const response = await axios.post(BASE_URL + REFRESH_TOKEN_API, payload);
               console.log(response,"dfdsffd")
                const newAccessToken = response.data.token;
                SetToken(newAccessToken)
                SetRefreshToken(response.data?.refreshToken)
                return axios(originalRequest);
            
           
        } catch (error) {
           console.log(error,"dsdsffd")
        }
    }

  
    return Promise.reject(error);
});