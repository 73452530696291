import React, { useState, useEffect } from "react";
import Sidebar from "../../CommonComponent/sidebar/Sidebar";
import TopNavbar from "../../CommonComponent/topNavbar/TopNavbar";
import { Footer } from "../../Footer";
import { GetNotificationMes } from "../../redux/auth-reducer/auth-service";
import dayjs from "dayjs";




const SuperNotification = () => {
  const [data, setData] = useState(false);
  const [itemData, setItemData] = useState([]);
  const [itemLastData, setItemLastData] = useState([]);
  const [notificationMes, setNotificationMes] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [notificationsPerPage] = useState(10);
  const [activePage, setActivePage] = useState(1);

  useEffect(() => {
    notificationMes?.map((item) => {
      var user = item?.userName.split(" ");
      var dataList = user?.[0];
      var dataList2 = user?.[1];
      var listItem = dataList.charAt(0);
      var listItem2 = dataList2.charAt(0);
      setItemData(listItem);
      setItemLastData(listItem2);
    });
  }, [notificationMes]);

  useEffect(() => {
    const NotificationMessageFun = async () => {
      await GetNotificationMes().then((response) => {
        setNotificationMes(response?.data?.dataList);
      });
    };
    NotificationMessageFun();
  }, []);

  const indexOfLastNotification = currentPage * notificationsPerPage;
  const indexOfFirstNotification = indexOfLastNotification - notificationsPerPage;
  const currentNotifications = notificationMes?.slice(indexOfFirstNotification, indexOfLastNotification);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    setActivePage(pageNumber);
  }
  const totalPages = Math.ceil(notificationMes.length / notificationsPerPage);

  const sidebarfun = () => {
    setData(!data);
  };
  return (
    <>
      <TopNavbar sidebarfun={sidebarfun} />
      <div className="dasboard-group">
        <Sidebar data={data} />
        <div
          className={
            data ? "dashboard-content full-contnet" : "dashboard-content"
          }
        >
          <h1 className="heading-dash">Notification</h1>
          <div className="group_data_table">
            {/* <div className="notification_box">
              {notificationMes == null ? (
                <div>No data found!</div>
              ) : (
                notificationMes.map((item) => (
                  <div className="notification">
                    <div className="notification-right">
                      <div className="profile-pic2">
                        {itemData} {itemLastData}
                      </div>
                      <div className="ms-3">
                        <h1>{item?.userName}</h1>
                        <p>
                          {item?.message}
                          <br></br>
                          {dayjs(item?.createDate).format("MM/DD/YYYY")}{" "}
                          {dayjs(item?.createDate).format("HH:mm:ss")}
                        </p>
                      </div>
                    </div>
              
                  </div>
                ))
              )}
            </div> */}

            <div className="notification_box">

       

            {currentNotifications?.map((ele) => (
                <div className="notification p-2" key={ele.id}>
                  <div className="notification-right">
                    <div className="profile-pic2">
                      {itemData} {itemLastData}
                    </div>
                    <div className="ms-3 notification-area">
                      <h1>{ele?.userName}</h1>
                      <div className="noti-msg">
                        {ele?.message}
                      </div>

                      <div className="Notification-Date">
                 
                      {dayjs(ele?.createDate).format("MM/DD/YYYY")}{" "}
                      {dayjs(ele?.createDate).format("HH:mm:ss")}
                    </div>
                    </div>

                  </div>
                </div>

              ))}

              {/* Pagination Controls */}
              <div className="pagination notification-sec">
                {Array.from({ length: totalPages }, (_, index) => (
                  <button className={`pagenation-item ${activePage === index + 1 ? 'active' : ''}`} key={index + 1} onClick={() => paginate(index + 1)}>
                    {index + 1}
                  </button>
                ))}
              </div>

            </div>
          </div>
        </div>
        <div className={data ? "Footer" : "footer-content"}>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default SuperNotification;
