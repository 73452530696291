import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import imageCopy from '../../assets/images/logo-2.png'
import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Col, Dropdown, Form, Modal, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { ManagerGetById, PlayerGetById } from '../../redux/auth-reducer/auth-service';
import { GetToken, RemovRoles, RemoveRefreshToken, RemoveToken, RemoveUser } from '../../utils/helper/helper';

function Headertwo() {
  const [selectedOption, setSelectedOption] = useState('UserRegistration');
  const [tokenItem, setTokenItem] = useState()
  const [smShow, setSmShow] = useState(false);
  const [userData, setUserData] = useState();
  const [item, setItem] = useState(window.location.pathname);
  const navigate = useNavigate();
  const handleClose = () => setSmShow(false);
  const handleShow = () => setSmShow(true);
  function subBtn() {
    if (selectedOption === 'UserRegistration') {
      navigate('/UserRegistration')
    } else if (selectedOption === 'Organization') {
      navigate('/Organization')
    }

  }
  const close = () => {
    setSmShow(false)
  }
  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };



  useEffect(() => {
    setTokenItem(GetToken())
  }, []);

  async function logout() {
    RemoveToken();
    RemoveRefreshToken();
    RemoveUser();
    RemovRoles()
    localStorage.removeItem('Role');
    navigate("/");
    window.location.reload();
  }

  const handleActiveStatus = () => {
   
   
  };

  const isRole = localStorage.getItem('role');

  useEffect(() => {
    if (isRole == "Manager") {
      const UserGet = async () => {
        const userName = localStorage.getItem('isUser');
    
      
        const isUser = JSON.parse(localStorage.getItem('isUser'));
        const userId = isUser?.userId;
      
      
     

        await ManagerGetById(userId).then((res) => {
          setUserData(res?.data);
         
        });
      };
      UserGet();
    }
    else if (isRole == "Player") {
      const UserGet = async () => {
        const isUser = JSON.parse(localStorage.getItem('isUser'));
        const userId = isUser.userId;
        await PlayerGetById(userId).then((res) => {
          setUserData(res?.data);
          
        });
      };
      UserGet();
    } else if (isRole == "Web Admin") {
      const UserGet = async () => {
        const isUser = JSON.parse(localStorage.getItem('isUser'));
        const userId = isUser?.userId;
        
        await ManagerGetById(userId).then((res) => {
          setUserData(res?.data);
         
        });
      };
      UserGet();
    }

  }, [])
  const handleLandingPage = () => {
    if (localStorage.getItem("role") == "Manager") {
      navigate('/SuperAdmin/SuperDashboard');

    } else if (localStorage.getItem("role") == "Player") {
      navigate('/Player/Player_dashboard');

    } else {
      navigate('/WebAdmin/Dashboard');
    }

  }


  return (
    <>
      <Navbar expand="lg" className="header">
        <Container fluid>
          <Navbar.Brand ><Link to="/Home"><img src="../image/logo1.png" className='landing-header-logo'/></Link></Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="me-auto my-2 my-lg-0 justify-content-end flex-grow-1 pe-3"
              style={{ maxHeight: '100px' }}
              navbarScroll
            >

              <Link to="/Home" className={`nav-link ${item == "/" || item == "/Home" ? " active" : ""}`} onClick={() => handleActiveStatus()}>Home</Link>
              <Link to="/AboutUs" className={`nav-link ${item == "/AboutUs" ? " active" : ""}`} onClick={() => handleActiveStatus()}>About Us</Link>
              <Link to="/Works" className={`nav-link ${item == "/Works" ? " active" : ""}`} onClick={() => handleActiveStatus()}>How it Works</Link>
              <Link to="/ManagerTips" className={`nav-link ${item == "/ManagerTips" ? " active" : ""}`} onClick={() => handleActiveStatus()}>Manager Tips</Link>
              <Link to="/Faq" className={`nav-link ${item == "/Faq" ? " active" : ""}`} onClick={() => handleActiveStatus()}>FAQ</Link>
             
              <Link to="/ContactUs" className={`nav-link ${item == "/ContactUs" ? " active" : ""}`} onClick={() => handleActiveStatus()}>Contact Us</Link>
            </Nav>
            <div className="d-flex align-items-center">
              {
                tokenItem == null ? <>
                  <Link to="/login"><Button variant="outline-success">Login </Button></Link>
                  <Button variant="primary" onClick={handleShow}>Sign Up</Button>
                </> : <>
                <Button variant='primary' onClick={handleLandingPage} style={{ fontSize: "15px", padding: "5px 5px", marginRight: "15px" }}>Dashboard page</Button>
                  <Dropdown>
                
                    <Dropdown.Toggle variant="none" className='d-flex align-items-center p-0 '>

                      <OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip1">

                        {userData?.firstName + " " + userData?.lastName}
                      </Tooltip>}>
                        <div className='text-hide user-list-item'>{userData?.firstName + " " + userData?.lastName}</div>
                      </OverlayTrigger>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>





                      <Dropdown.Item onClick={logout} >
                        Logout
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </>
              }

            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Modal show={smShow} onHide={handleClose} centered>


        <img src="../image/circle_full_width.png" width="100%" height={80} />

        <Modal.Body className='pt-0'>
          <h2 className='text-center heading-get-started'><b>Get Started Now</b></h2>
          <p className='text-center mb-4 mt-3'>Create an Account as</p>

          <div className='grop-input-radio d-flex justify-content-center'>
            <Form.Check className="me-3" label=" Player" type="radio" name="flexRadioDefault" value="UserRegistration"
              checked={selectedOption === 'UserRegistration'} onChange={handleOptionChange} id="flexRadioDefault"
            />

            <Form.Check label="Xcutta Manager" type="radio" name="flexRadioDefault" value="Organization" checked={selectedOption === 'Organization'}
              onChange={handleOptionChange} id="flexRadioDefault2"
            />
          </div>
          <Row>

            <Col md={12} className='text-center mt-4 pb-5'>
              <Button className='text-center submit-btn' type="submit" onClick={subBtn}>Continue</Button>
              <Button className='text-center btn btn-danger ms-3 ' onClick={close} type="submit"  >Close</Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Headertwo;