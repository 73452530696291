
import { useState } from "react";
import * as icons from "react-bootstrap-icons";
import { Link } from "react-router-dom";


function Sidebar({ data }) {
  const [item, setItem] = useState(window.location.pathname);

  const roles = localStorage.getItem("role");

  const handleActiveStatus = () => {
    setItem(window.location.pathname);
  };

 
  return (
    <>
      <div className={data ? "sidebar mystyle" : "sidebar"}>
        <ul className="navbar">
          {roles == "Web Admin" ? (
            <li
              className={`sidebar-links ${
                item == "/WebAdmin/Dashboard" ? " active" : ""
              }`}
              onClick={() => handleActiveStatus()}
            >
              <Link to="/WebAdmin/Dashboard">
                <icons.ColumnsGap className="icons-sidebar" />
                Dashboard
              </Link>
            </li>
          ) : roles == "Manager" ? (
            <li
              className={`sidebar-links ${
                item == "/SuperAdmin/SuperDashboard" ? " active" : ""
              }`}
              onClick={() => handleActiveStatus()}
            >
              <Link to="/SuperAdmin/SuperDashboard">
                <icons.ColumnsGap className="icons-sidebar" />
                Dashboard
              </Link>
            </li>
          ) : (
            <li
              className={`sidebar-links ${
                item == "/Player/Player_dashboard" ? " active" : ""
              }`}
              onClick={() => handleActiveStatus()}
            >
              <Link to="/Player/Player_dashboard">
                <icons.ColumnsGap className="icons-sidebar" />
                Dashboard
              </Link>
            </li>
          )}

          {roles == "Web Admin" ? (
            <li
              className={`sidebar-links ${
                item == "/WebAdmin/profile" ? " active" : ""
              }`}
              onClick={() => handleActiveStatus()}
            >
              <Link to="/WebAdmin/profile">
                <icons.Person className="icons-sidebar" />
                Profile
              </Link>
            </li>
          ) : roles == "Manager" ? (
            <li
              className={`sidebar-links ${
                item == "/SuperAdmin/Profile" ? " active" : ""
              }`}
              onClick={() => handleActiveStatus()}
            >
              <Link to="/SuperAdmin/Profile">
                <icons.Person className="icons-sidebar" />
                Profile
              </Link>
            </li>
          ) : (
            <li
              className={`sidebar-links ${
                item == "/Player/Profile" ? " active" : ""
              }`}
              onClick={() => handleActiveStatus()}
            >
              <Link to="/Player/Profile">
                <icons.Person className="icons-sidebar" />
                Profile
              </Link>
            </li>
          )}

          {roles == "Web Admin" ? (
            <li
              className={`sidebar-links ${
                item == "/WebAdmin/User" ? " active" : ""
              }`}
              onClick={() => handleActiveStatus()}
            >
              <Link to="/WebAdmin/User">
                <icons.People className="icons-sidebar" />
                Players
              </Link>
            </li>
          ) : roles == "Manager" ? (
            <li
              className={`sidebar-links ${
                item == "/SuperAdmin/UserSuper" ? " active" : ""
              }`}
              onClick={() => handleActiveStatus()}
            >
              <Link to="/SuperAdmin/UserSuper">
                <icons.People className="icons-sidebar" />
                Players
              </Link>
            </li>
          ) : (
            ""
          )}

          {roles == "Web Admin" ? (
            <li
              className={`sidebar-links ${
                item == "/WebAdmin/Admin_auction" ? " active" : ""
              }`}
              onClick={() => handleActiveStatus()}
            >
              <Link to="/WebAdmin/Admin_auction">
                <svg
                  width="23"
                  height="30"
                  viewBox="0 0 29 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M28.1974 13.6638C27.4997 12.9662 26.4532 12.8257 25.6143 13.2412L16.449 4.07602C16.8645 3.23707 16.7241 2.19058 16.0263 1.49293C15.6027 1.06929 15.0394 0.835938 14.4402 0.835938C13.841 0.835938 13.2777 1.06922 12.854 1.49293L7.40459 6.94236C6.53007 7.81694 6.53007 9.23998 7.40459 10.1146C7.84191 10.5518 8.41636 10.7705 8.99082 10.7705C9.3329 10.7705 9.67472 10.6923 9.98787 10.5372L12.9484 13.4978L0.71902 25.727C0.459545 25.9864 0.459545 26.4071 0.71902 26.6666L3.02361 28.9712C3.14823 29.0958 3.31722 29.1658 3.49344 29.1658C3.66966 29.1658 3.83865 29.0958 3.96327 28.9712L16.1927 16.7418L19.1524 19.7015C18.9999 20.0078 18.9187 20.3473 18.9187 20.6994C18.9187 21.2986 19.1522 21.8619 19.5758 22.2854C19.9994 22.709 20.5628 22.9424 21.162 22.9424C21.7612 22.9424 22.3244 22.709 22.7481 22.2854L28.1974 16.836C28.6211 16.4123 28.8545 15.849 28.8545 15.2498C28.8545 14.6506 28.6211 14.0875 28.1974 13.6638ZM9.63693 9.17509C9.28065 9.53118 8.70079 9.53118 8.34438 9.17483C7.9879 8.81836 7.9879 8.2383 8.34438 7.88189L13.7938 2.43246C13.9665 2.25976 14.1961 2.16471 14.4402 2.16471C14.6845 2.16471 14.9141 2.25982 15.0867 2.43246C15.4431 2.78894 15.4431 3.36893 15.0867 3.72541L9.63693 9.17509ZM3.49344 27.5617L2.12851 26.1968L13.8881 14.4372L15.2531 15.8022L3.49344 27.5617ZM11.0109 9.68062L15.5926 5.09887L24.5914 14.0978L20.0097 18.6795L11.0109 9.68062ZM27.2578 15.8965L21.8083 21.3459C21.6356 21.5186 21.4061 21.6137 21.1619 21.6137C20.9177 21.6137 20.6881 21.5186 20.5155 21.3459C20.5155 21.3459 20.5155 21.3459 20.5154 21.3459C20.3427 21.1732 20.2477 20.9436 20.2477 20.6995C20.2477 20.4553 20.3428 20.2257 20.5155 20.053L25.9652 14.6034C26.1379 14.4308 26.3673 14.3359 26.6113 14.3359C26.8555 14.3358 27.0851 14.4308 27.2578 14.6036C27.4305 14.7763 27.5255 15.0059 27.5255 15.2501C27.5255 15.4942 27.4304 15.7238 27.2578 15.8965Z"
                    fill="white"
                  />
                </svg>
                Auctions
              </Link>
            </li>
          ) : roles == "Manager" ? (
            <li
              className={`sidebar-links ${
                item == "/SuperAdmin/Sports" ? " active" : ""
              }`}
              onClick={() => handleActiveStatus()}
            >
              <Link to="/SuperAdmin/Sports">
                <icons.CircleHalf className="icons-sidebar" />
                Sports
              </Link>
            </li>
          ) : (
            <li
              className={`sidebar-links ${
                item == "/Player/Bid" ? " active" : ""
              }`}
              onClick={() => handleActiveStatus()}
            >
              <Link to="/Player/Bid">
                <icons.CircleHalf className="icons-sidebar" />
                Bid History
              </Link>
            </li>
          )}

          {roles == "Web Admin" ? (
            <li
              className={`sidebar-links ${
                item == "/WebAdmin/ViewOrganization" ? " active" : ""
              }`}
              onClick={() => handleActiveStatus()}
            >
              <Link to="/WebAdmin/ViewOrganization">
                <icons.Person className="icons-sidebar" />
                Xcutta Managers
              </Link>
            </li>
          ) : roles == "Manager" ? (
            <li
            className={`sidebar-links ${
              item == "/SuperAdmin/Team" ? " active" : ""
            }`}
            onClick={() => handleActiveStatus()}
          >
            <Link to="/SuperAdmin/Team">
              <icons.People className="icons-sidebar" />
              Teams
            </Link>
          </li>
          ) : (
            <li
              className={`sidebar-links ${
                item == "/Player/AuctionPlayer" || item == "/Player/Auction"
                  ? " active"
                  : ""
              }`}
              onClick={() => handleActiveStatus()}
            >
              <Link to="/Player/AuctionPlayer">
                <icons.Trophy className="icons-sidebar" />
                Auction
              </Link>
            </li>
          )}

          {roles == "Web Admin" ? (
            <li
              className={`sidebar-links ${
                item == "/WebAdmin/Admin_report" ? " active" : ""
              }`}
              onClick={() => handleActiveStatus()}
            >
              <Link to="/WebAdmin/Admin_report">
                <icons.FileEarmarkText className="icons-sidebar" />
                Report
              </Link>
            </li>
          ) : roles == "Manager" ? (
          

            <li
            className={`sidebar-links ${
              item == "/SuperAdmin/Tournament" ||
              item == "/SuperAdmin/CreateTournament"
                ? " active"
                : ""
            }`}
            onClick={() => handleActiveStatus()}
            >
            <Link to="/SuperAdmin/Tournament">
              <icons.Trophy className="icons-sidebar" />
              Tournaments
            </Link>
            </li>
          ) : (
            <li
              className={`sidebar-links ${
                item == "/Player/Notification" || item == "/Player/Notification"
                  ? " active"
                  : ""
              }`}
              onClick={() => handleActiveStatus()}
            >
              <Link to="/Player/Notification">
                <icons.Bell className="icons-sidebar" />
                Notification
              </Link>
            </li>
          )}

          {roles == "Web Admin" ? (
           <li
           className={`sidebar-links ${
             item == "/WebAdmin/Admin_notification" ? " active" : ""
           }`}
           onClick={() => handleActiveStatus()}
         >
           <Link to="/WebAdmin/Admin_notification">
             <icons.Bell className="icons-sidebar" />
             Notification
           </Link>
         </li>
          ) : roles == "Manager" ? (
            <li
              className={`sidebar-links ${
                item == "/SuperAdmin/Auction" ||
                item == "/SuperAdmin/CreateAuction"
                  ? " active"
                  : ""
              }`}
              onClick={() => handleActiveStatus()}
            >
              <Link to="/SuperAdmin/Auction">
                <svg
                  width="23"
                  height="30"
                  viewBox="0 0 29 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M28.1974 13.6638C27.4997 12.9662 26.4532 12.8257 25.6143 13.2412L16.449 4.07602C16.8645 3.23707 16.7241 2.19058 16.0263 1.49293C15.6027 1.06929 15.0394 0.835938 14.4402 0.835938C13.841 0.835938 13.2777 1.06922 12.854 1.49293L7.40459 6.94236C6.53007 7.81694 6.53007 9.23998 7.40459 10.1146C7.84191 10.5518 8.41636 10.7705 8.99082 10.7705C9.3329 10.7705 9.67472 10.6923 9.98787 10.5372L12.9484 13.4978L0.71902 25.727C0.459545 25.9864 0.459545 26.4071 0.71902 26.6666L3.02361 28.9712C3.14823 29.0958 3.31722 29.1658 3.49344 29.1658C3.66966 29.1658 3.83865 29.0958 3.96327 28.9712L16.1927 16.7418L19.1524 19.7015C18.9999 20.0078 18.9187 20.3473 18.9187 20.6994C18.9187 21.2986 19.1522 21.8619 19.5758 22.2854C19.9994 22.709 20.5628 22.9424 21.162 22.9424C21.7612 22.9424 22.3244 22.709 22.7481 22.2854L28.1974 16.836C28.6211 16.4123 28.8545 15.849 28.8545 15.2498C28.8545 14.6506 28.6211 14.0875 28.1974 13.6638ZM9.63693 9.17509C9.28065 9.53118 8.70079 9.53118 8.34438 9.17483C7.9879 8.81836 7.9879 8.2383 8.34438 7.88189L13.7938 2.43246C13.9665 2.25976 14.1961 2.16471 14.4402 2.16471C14.6845 2.16471 14.9141 2.25982 15.0867 2.43246C15.4431 2.78894 15.4431 3.36893 15.0867 3.72541L9.63693 9.17509ZM3.49344 27.5617L2.12851 26.1968L13.8881 14.4372L15.2531 15.8022L3.49344 27.5617ZM11.0109 9.68062L15.5926 5.09887L24.5914 14.0978L20.0097 18.6795L11.0109 9.68062ZM27.2578 15.8965L21.8083 21.3459C21.6356 21.5186 21.4061 21.6137 21.1619 21.6137C20.9177 21.6137 20.6881 21.5186 20.5155 21.3459C20.5155 21.3459 20.5155 21.3459 20.5154 21.3459C20.3427 21.1732 20.2477 20.9436 20.2477 20.6995C20.2477 20.4553 20.3428 20.2257 20.5155 20.053L25.9652 14.6034C26.1379 14.4308 26.3673 14.3359 26.6113 14.3359C26.8555 14.3358 27.0851 14.4308 27.2578 14.6036C27.4305 14.7763 27.5255 15.0059 27.5255 15.2501C27.5255 15.4942 27.4304 15.7238 27.2578 15.8965Z"
                    fill="white"
                  />
                </svg>
                Auctions
              </Link>
            </li>
          ) : (
            <li
              className={`sidebar-links ${
                item == "/Player/Account_settings" ? " active" : ""
              }`}
              onClick={() => handleActiveStatus()}
            >
              <Link to="/Player/Account_settings">
                <icons.Gear className="icons-sidebar" />
                Account Settings
              </Link>
            </li>
          )}

          {roles == "Web Admin" ? (
            <li
            className={`sidebar-links ${
              item == "/WebAdmin/AccountHistory" ? " active" : ""
            }`}
            onClick={() => handleActiveStatus()}
          >
            <Link to="/WebAdmin/AccountHistory">
              <icons.PersonPlus className="icons-sidebar" />
              Account History
            </Link>
          </li>
          ) : roles == "Manager" ? (
            <li
              className={`sidebar-links ${
                item == "/SuperAdmin/Announcement" ? " active" : ""
              }`}
              onClick={() => handleActiveStatus()}
            >
              <Link to={"/SuperAdmin/Announcement"}>
                <icons.Megaphone className="icons-sidebar" />
                Announcement
              </Link>
            </li>
          ) : (
            <li
            className={`sidebar-links ${
              item == "/Player/PlayerAnnouncment" ? " active" : ""
            }`}
            onClick={() => handleActiveStatus()}
          >
            <Link to={"/Player/PlayerAnnouncment"}>
              <icons.Megaphone className="icons-sidebar" />
              Announcement
            </Link>
          </li>
            
          )}

          {roles == "Web Admin" ? (
           <li   className={`sidebar-links ${
            item == "/WebAdmin/Query" ? " active" : ""
          }`}
          onClick={() => handleActiveStatus()}  >
          <Link to={"/WebAdmin/Query"}>
           <icons.QuestionCircle className="icons-sidebar" /> Queries</Link>
          </li>
          ) : roles == "Manager" ? (
            <li
              className={`sidebar-links ${
                item == "/SuperAdmin/Admin" ? " active" : ""
              }`}
              onClick={() => handleActiveStatus()}
            >
              <Link to={"/SuperAdmin/Admin"}>
                <icons.Person className="icons-sidebar" />
                Add Admin
              </Link>
            </li>
          ) : (
            <li
            className={`sidebar-links ${
              item == "/Player/Secondary_Market" ||
              item == "/Player/Secondary_Market"
                ? " active"
                : ""
            }`}
            onClick={() => handleActiveStatus()}
          >
            <Link to="/Player/Secondary_Market">
              <icons.Trophy className="icons-sidebar" />
              Secondary Market
            </Link>
          </li>
          )}

          {roles == "Web Admin" ? "" : roles == "Manager" ? "" : ""}

          {roles == "Web Admin" ? (
           ""
          ) : roles == "Manager" ? (
            <li
              className={`sidebar-links ${
                item == "/SuperAdmin/SuperNotification" ? " active" : ""
              }`}
              onClick={() => handleActiveStatus()}
            >
              <Link to={"/SuperAdmin/SuperNotification"}>
                <icons.Bell className="icons-sidebar" />
                Notification
              </Link>
            </li>
          ) : (
        
            <li
            className={`sidebar-links ${
              item == "/Player/SecodaryBid" ||
              item == "/Player/SecodaryBid"
                ? " active"
                : ""
            }`}
            onClick={() => handleActiveStatus()}
          >
            <Link to="/Player/SecodaryBid">
              <icons.CircleHalf className="icons-sidebar" />
              Secondary Market Bid
            </Link>
          </li>
          )}



      {roles == "Web Admin" ? (
          <li
          className={`sidebar-links ${
            item == "/WebAdmin/Secondary_Market_AnnouncementA" ? " active" : ""
          }`}
          onClick={() => handleActiveStatus()}
        >
          <Link to={"/WebAdmin/Secondary_Market_AnnouncementA"}>
            <icons.Megaphone className="icons-sidebar" />
           
             Secondary Market Announcement
          </Link>
        </li>
          ) : roles == "Manager" ? (
            <li
            className={`sidebar-links ${
              item == "/SuperAdmin/Secondary_Market_Announcement" ? " active" : ""
            }`}
            onClick={() => handleActiveStatus()}
          >
            {/* <Link to={"/SuperAdmin/Secondary_Market_Announcement"}>
              <icons.Megaphone className="icons-sidebar" />
               Secondary Market Announcement
            </Link> */}
             <Link to={"/SuperAdmin/Secondary_Market_Announcement"}>
                <icons.Megaphone className="icons-sidebar" />
                Announcement
              </Link>
          </li>
          ) : (
        
            <li
            className={`sidebar-links ${
              item == "/Player/Secondary_Market_AnnouncementP" ? " active" : ""
            }`}
            onClick={() => handleActiveStatus()}
          >
            <Link to={"/Player/Secondary_Market_AnnouncementP"}>
              <icons.Megaphone className="icons-sidebar announcement" />
               Secondary Market Announcement
            </Link>
             
          </li>
          )} 
        </ul>
      </div>
    </>
  );
}
export default Sidebar;

